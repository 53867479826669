import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/map';

declare var angular: any;

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
 
@Injectable()
export class DataService {

    artistsEndpoint = 'https://data.mothership.tools/mothershiptools-ukprod/mothership/label/10/labels_data.json';
    newsEndpoint = 'https://data.mothership.tools/mothershiptools-ukprod/mothership/label/10/label_news.json';
    instagramEndpoint = 'https://www.instagram.com/rcalabelgroupuk/?__a=1';
    releasesEndpoint = 'https://data.mothership.tools/mothershiptools-ukprod/mothership/label/10/releases.json';
    datesEndpoint = 'https://www.rcarecords.com/wp-json/wp/v2/events';
    datesUsEndpoint = 'https://data.mothership.tools/mothershiptools-ukprod/mothership/label/10/tour_dates.json';

    artists;
    news;
    instagram;
    releases;
    videos;
    dates;
    datesUs;
    
    observable_artists;
    observable_news;
    observable_instagram;
    observable_releases;
    observable_dates;
    observable_dates_us;
 
    constructor(private http:HttpClient) {}

    getVideos() {

        var artistVideos = [];
        var artistHttp = this.http;

        angular.forEach(this.artists.artists, function(artistItem) {
          
          if (artistItem.artist_featured) {

            var videoEndpoint = 'https://data.mothership.tools/mothershiptools-ukprod/mothership/artist/' + artistItem.id + '/videos.json';

            artistHttp.get(videoEndpoint).subscribe(
              data => { artistVideos.push(data) },
              err => console.error(err),
              () => {}
            );

          }

        });

        this.videos = artistVideos;

        return this.videos;
    }
 
    getArtists() {

        if (this.artists) {
            console.log('Loading Artists from Cache');
            return Observable.of(this.artists);
        } else if (this.observable_artists) {
            console.log('Loading Artists from Cache');
            return this.observable_artists;
        } else {
            console.log('Loading Artists from Endpoint');
            this.observable_artists = this.http.get(this.artistsEndpoint, {
              observe: 'response'
            })
            .map(response =>  {
              this.observable_artists = null;
              if (response.status === 400) {
                return 'Request failed.';
              } else if (response.status === 200) {
                this.artists = response.body;
                return this.artists;
              }
            })
            .share();
            return this.observable_artists;
        }

        // return this.http.get('https://data.mothership.tools/mothershiptools-ukprod/mothership/label/10/labels_data.json');
    }

    getNews() {

        if (this.news) {
            console.log('Loading News from Cache');
            return Observable.of(this.news);
        } else if (this.observable_news) {
            console.log('Loading News from Cache');
            return this.observable_news;
        } else {
            console.log('Loading News from Endpoint');
            this.observable_news = this.http.get(this.newsEndpoint, {
              observe: 'response'
            })
            .map(response =>  {
              this.observable_news = null;
              if (response.status === 400) {
                return 'Request failed.';
              } else if (response.status === 200) {
                this.news = response.body;
                return this.news;
              }
            })
            .share();
            return this.observable_news;
        }

        // return this.http.get('https://data.mothership.tools/mothershiptools-ukprod/mothership/label/10/label_news.json');
    }

    getInstagram() {

        if (this.instagram) {
            console.log('Loading Instagram from Cache');
            return Observable.of(this.instagram);
        } else if (this.observable_instagram) {
            console.log('Loading Instagram from Cache');
            return this.observable_instagram;
        } else {
            console.log('Loading Instagram from Endpoint');
            this.observable_instagram = this.http.get(this.instagramEndpoint, {
              observe: 'response'
            })
            .map(response =>  {
              this.observable_instagram = null;
              if (response.status === 400) {
                return 'Request failed.';
              } else if (response.status === 200) {
                this.instagram = response.body;
                return this.instagram;
              }
            })
            .share();
            return this.observable_instagram;
        }

        // return this.http.get('https://www.instagram.com/rcalabelgroupuk/?__a=1');
    }

    getReleases() {

        if (this.releases) {
            console.log('Loading Releases from Cache');
            return Observable.of(this.releases);
        } else if (this.observable_releases) {
            console.log('Loading Releases from Cache');
            return this.observable_releases;
        } else {
            console.log('Loading Releases from Endpoint');
            this.observable_releases = this.http.get(this.releasesEndpoint, {
              observe: 'response'
            })
            .map(response =>  {
              this.observable_releases = null;
              if (response.status === 400) {
                return 'Request failed.';
              } else if (response.status === 200) {
                this.releases = response.body;
                return this.releases;
              }
            })
            .share();
            return this.observable_releases;
        }

        // return this.http.get('https://data.mothership.tools/mothershiptools-ukprod/mothership/label/10/releases.json');
    }

    getDates() {

        if (this.dates) {
            console.log('Loading Dates from Cache');
            return Observable.of(this.dates);
        } else if (this.observable_dates) {
            console.log('Loading Dates from Cache');
            return this.observable_dates;
        } else {
            console.log('Loading Dates from Endpoint');
            this.observable_dates = this.http.get(this.datesEndpoint, {
              observe: 'response'
            })
            .map(response =>  {
              this.observable_dates = null;
              if (response.status === 400) {
                return 'Request failed.';
              } else if (response.status === 200) {
                this.dates = response.body;
                return this.dates;
              }
            })
            .share();
            return this.observable_dates;
        }

        // return this.http.get('https://data.mothership.tools/mothershiptools-ukprod/mothership/label/10/tour_dates.json');
    }

    getDatesUs() {

        if (this.datesUs) {
            console.log('Loading Dates US from Cache');
            return Observable.of(this.datesUs);
        } else if (this.observable_dates_us) {
            console.log('Loading Dates US from Cache');
            return this.observable_dates_us;
        } else {
            console.log('Loading Dates 2 from Endpoint');
            this.observable_dates_us = this.http.get(this.datesUsEndpoint, {
              observe: 'response'
            })
            .map(response =>  {
              this.observable_dates_us = null;
              if (response.status === 400) {
                return 'Request failed.';
              } else if (response.status === 200) {
                this.datesUs = response.body;
                return this.datesUs;
              }
            })
            .share();
            return this.observable_dates_us;
        }

        // return this.http.get('https://data.mothership.tools/mothershiptools-ukprod/mothership/label/10/tour_dates.json');
    }
}