import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import * as $ from 'jquery';

import { DataService } from '../data.service';

import { FormControl } from '@angular/forms';

import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.css']
})
export class ArtistsComponent implements OnInit {

  public artists;
  public options;

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  // options: string[] = ['One', 'Two', 'Three'];

  constructor(private _dataService: DataService, private router: Router) { }

  ngOnInit() {
  	this.getArtists();

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    this.options = this.artists.artists;

    return this.options.filter(option => option.slug.toLowerCase().includes(filterValue));
  }

  getArtists() {

  	this._dataService.getArtists().subscribe(
      data => { this.artists = data},
      
      err => console.error(err),
      
      () => { 
        $('.loading-screen').fadeOut();
        console.log('Artists Loaded');
      }
    );

  }
  
  artistRoute(event) {

    this.router.navigate(['/artists', event.option.value]);

  }

}
