import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ArtistsComponent } from './artists/artists.component';
import { ArtistComponent } from './artist/artist.component';
import { NewsComponent } from './news/news.component';
import { HistoryComponent } from './history/history.component';
import { ContactComponent } from './contact/contact.component';
import { LiveComponent } from './live/live.component';

const routes: Routes = [
	{
    	path: '',
    	component: HomeComponent,
    },
    {
    	path: 'artists',
    	component: ArtistsComponent,
    },
    {
        path: 'artists/:slug',
        component: ArtistComponent,
    },
    {
    	path: 'news/:slug',
    	component: NewsComponent,
    },
    {
    	path: 'history',
    	component: HistoryComponent,
    },
    {
    	path: 'newsletter',
    	component: ContactComponent,
    }
    ,
    {
        path: 'live',
        component: LiveComponent,
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
