import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgMarqueeModule } from 'ng-marquee';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { DataService } from './data.service';
import { SafePipe } from './safepipe';
import { ArtistsComponent } from './artists/artists.component';
import { HistoryComponent } from './history/history.component';
import { ContactComponent } from './contact/contact.component';
import { NewsComponent } from './news/news.component';
import { FooterComponent } from './footer/footer.component';
import { ArtistComponent } from './artist/artist.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './artists/material';

import { YouTubePlayerModule } from '@angular/youtube-player';
import { LiveComponent } from './live/live.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ArtistsComponent,
    HistoryComponent,
    ContactComponent,
    NewsComponent,
    SafePipe,
    FooterComponent,
    ArtistComponent,
    LiveComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SlickCarouselModule,
    NgMarqueeModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    BrowserAnimationsModule,
    YouTubePlayerModule,
  ],
  providers: [DataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
