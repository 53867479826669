import { Component, OnInit } from '@angular/core';

import { DataService } from '../data.service';

import { Meta, Title } from '@angular/platform-browser';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import * as $ from 'jquery';
import Marquee3k from 'marquee3000';

declare var angular: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public artists;
  public news;
  public instagram;
  public releases;
  public videos;

  public dates;
  public datesUs;
  public allDates;
  public sortedDates;

  public YT: any;
  public video: any;
  public player: any;

  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "dots": false,
    "arrows": false,
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": 15000,
    "speed": 1000,
    "fade": true,
    "lazyLoad": "progressive",
    "cssEase": "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
  };

  constructor(private _dataService: DataService, public meta: Meta, public title: Title) { }

  ngOnInit() {
    this.allDates = [];
    
  	this.getArtists();
  	this.getNews();
  	this.getInstagram();
    this.getReleases();
    this.getDates();

    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  slickInit(e) {
    // console.log('slick initialized');

    // var iframes = document.getElementsByTagName('iframe');
    // this.resizePlayer(iframes, 16/9);
  }

  afterChange(e) {
    // this.playPauseVideo(e.slick, 'play');
    // var iframes = document.getElementsByTagName('iframe');
    // this.resizePlayer(iframes, 16/9);
  }

  beforeChange(e) {
    // this.playPauseVideo(e.slick, 'pause');
    // var iframes = document.getElementsByTagName('iframe');
    // this.resizePlayer(iframes, 16/9);
  }

  playPauseVideo(slick, control) {

    var currentSlide, slideType, player, video;

    currentSlide = document.getElementsByClassName('slick-current');
    player = document.querySelector('.slick-current > iframe');

    if (currentSlide[0].classList.contains('vimeo')) {

      switch (control) {
        case "play":
          this.postMessageToPlayer(player, {
            "method": "play",
            "value" : 1
          });
          break;
        case "pause":
          this.postMessageToPlayer(player, {
            "method": "pause",
            "value": 1
          });
          break;
      }

    } else if (currentSlide[0].classList.contains('youtube')) {

      switch (control) {
        case "play":
          this.postMessageToPlayer(player, {
            "event": "command",
            "func": "mute"
          });
          this.postMessageToPlayer(player, {
            "event": "command",
            "func": "playVideo"
          });
          break;
        case "pause":
          this.postMessageToPlayer(player, {
            "event": "command",
            "func": "pauseVideo"
          });
          break;
      }

    } else if (currentSlide[0].classList.contains('video')) {

      video = currentSlide.children("video").get(0);
      if (video != null) {
        if (control === "play"){
          video.play();
        } else {
          video.pause();
        }
      }

    }
  }

  postMessageToPlayer(player, command){
    console.log('postMessageToPlayer');
    if (player == null || command == null) return;
      player.contentWindow.postMessage(JSON.stringify(command), "*");
  }

  resizePlayer(iframes, ratio) {
    console.log('resizePlayer called');

    if (!iframes[0]) return;
    var win = document.getElementById('videoSlider'),
        width = win.offsetWidth,
        playerWidth,
        height = win.offsetHeight,
        playerHeight,
        ratio = ratio || 16/9;

    angular.forEach(iframes, function(iframe) {
      
      if (width / ratio < height) {

        playerWidth = Math.ceil(height * ratio);
        
        iframe.width = playerWidth;
        iframe.height = height;

        iframe.style.left = (width - playerWidth) / 2;
        iframe.style.top  = 0;

      } else {
        
        playerHeight = Math.ceil(width / ratio);

        iframe.width = width;
        iframe.height = playerHeight;

        iframe.style.left = 0;
        iframe.style.top  = (height - playerHeight) / 2;

      }

    });

  }

  getVideos() {
    this.videos = this._dataService.getVideos();
  }

  getArtists() {

  	this._dataService.getArtists().subscribe(
      data => { this.artists = data },
      
      err => console.error(err),
      
      () => { 
        // this.getVideos();
        this.meta.updateTag({ name: 'description', content: this.artists.label_seo_description }); 
        this.meta.updateTag({ name: 'keywords', content: this.artists.label_seo_keywords }); 
        this.title.setTitle(this.artists.label_seo_title);

        setTimeout(function(e) {
          $('.loading-screen').fadeOut()
        }, 3000);

        console.log('Artists Loaded');
      }
    );

  }

  getNews() {

  	this._dataService.getNews().subscribe(
      data => { this.news = data },
      
      err => console.error(err),
      
      () => {
        console.log('News Loaded');
      }
    );

  }

  getInstagram() {

  	this._dataService.getInstagram().subscribe(
      data => { this.instagram = data },
      
      err => console.error(err),
      
      () => { 
        console.log('Instagram Loaded'); 
      }
    );

  }

  getReleases() {

    this._dataService.getReleases().subscribe(
      data => { this.releases = data },
      
      err => console.error(err),
      
      () => {
        console.log('Releases Loaded');
      }
    );

  }

  getDates() {

    this._dataService.getDates().subscribe(
      data => { 

        data.forEach(item => {

          var date = [];

          var dateString = new Date(item.acf.eventdate);

          var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

          date['order'] = new Date(dateString.getFullYear() + '-' + dateString.getMonth() + '-' + dateString.getDate());
          date['date'] = item.acf.eventdate;
          date['year'] = dateString.getFullYear();
          date['month'] = months[dateString.getMonth()];
          date['day'] = dateString.getDate();
          date['title'] = item.title.rendered;

          this.allDates.push(date);

        });

        this.getDatesUS();

      },
      
      err => console.error(err),
      
      () => { 
        $('.loading-screen').fadeOut();
        console.log('Dates Loaded');
      }
    ); 

  }

  getDatesUS() {

    this._dataService.getDatesUs().subscribe(
      data => { 

        data.forEach(item => {

          var date = [];

          var year  = item.show_date_user.substring(0, 4);
          var month = item.show_date_user.substring(4, 6);
          var day   = item.show_date_user.substring(6, 8);

          var dateUS = new Date(year, month, day); 

          var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

          date['order'] = new Date(dateUS.getFullYear() + '-' + dateUS.getMonth() + '-' + dateUS.getDate());
          date['date'] = item.show_date_user;
          date['year'] = dateUS.getFullYear();
          date['month'] = months[dateUS.getMonth()];
          date['day'] = dateUS.getDate();
          date['title'] = item.title.rendered;

          this.allDates.push(date);

        });

        this.sortDates();

      },
      
      err => console.error(err),
      
      () => { 
        $('.loading-screen').fadeOut();
        console.log('Dates US Loaded');
      }
    );

  }

  sortDates() {
    this.allDates.sort((a, b) => a.order - b.order);
  }

  formatDate (date){

    console.log('formatting date');

    var dateOut = new Date(date);
    
    return dateOut;
  }

  scrollerLoaded(last) {
    if (last) {
      Marquee3k.init();
    }
  }

  iframeLoaded(e) {
      // setTimeout(()=>{
      //   this.playPauseVideo(e.slick, "play");
      // }, 1000);
  }

}