import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';

import { Meta, Title } from '@angular/platform-browser';

import { DataService } from '../data.service';

declare var angular: any;

@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.css']
})
export class ArtistComponent implements OnInit {

  public artist;

  constructor(private route: ActivatedRoute, private _dataService: DataService, public meta: Meta, public title: Title) {
  	this.route.params.subscribe( 
      params => this.getArtist(params['slug'])
    );
  }

  ngOnInit() {}

  toTitleCase(str) {
      return str.replace(
          /\w\S*/g,
          function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
      );
  }

  getArtist(slug) {

  	var artistObject;

  	this._dataService.getArtists().subscribe(
      data => { 
    		angular.forEach(data.artists, function(artistItem) {
    		  if (artistItem.slug == slug) {
    		  	artistObject = artistItem;
    		  }
    		});
		    this.artist = artistObject;
      },
      
      err => console.error(err),
      
      () => { 
        this.meta.updateTag({ name: 'description', content: this.artist.artist_seo_description }); 
        this.meta.updateTag({ name: 'keywords', content: this.artist.artist_seo_keywords }); 
        this.title.setTitle(this.toTitleCase(this.artist.artist_seo_title));
      	$('.loading-screen').fadeOut();
        console.log('Artist Loaded');
      }
    );

    

  }

}
