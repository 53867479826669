import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';

import { DataService } from '../data.service';

declare var angular: any;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  public news;

  constructor(private route: ActivatedRoute, private _dataService: DataService) {
  	this.route.params.subscribe( 
      params => this.getNews(params['slug'])
    );
  }

  ngOnInit() { }

  getNews(slug) {

  	var newsObject;

  	this._dataService.getNews().subscribe(
      data => { 
      	
      	angular.forEach(data, function(newsItem) {
		  if (newsItem.slug == slug) {
		  	newsObject = newsItem;
		  }
		});
	    this.news = newsObject;
      },
      
      err => console.error(err),
      
      () => {
      	$('.loading-screen').fadeOut();
        console.log('News Loaded');
      }
    );

  }

}
