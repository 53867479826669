import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import * as $ from 'jquery';

import { DataService } from '../data.service';

import { FormControl } from '@angular/forms';

import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css']
})
export class LiveComponent implements OnInit {

	public dates;
  public datesUs;
  public allDates;
  public sortedDates;

  constructor(private _dataService: DataService, private router: Router) { }

  ngOnInit() {

    this.allDates = [];

  	this.getDates();

  }

  getDates() {

  	this._dataService.getDates().subscribe(
      data => { 

        data.forEach(item => {

          var date = [];

          var dateString = new Date(item.acf.eventdate);

          var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

          date['order'] = new Date(dateString.getFullYear() + '-' + dateString.getMonth() + '-' + dateString.getDate());
          date['date'] = item.acf.eventdate;
          date['year'] = dateString.getFullYear();
          date['month'] = months[dateString.getMonth()];
          date['day'] = dateString.getDate();
          date['title'] = item.title.rendered;

          this.allDates.push(date);

        });

        this.getDatesUS();

      },
      
      err => console.error(err),
      
      () => { 
        $('.loading-screen').fadeOut();
        console.log('Dates Loaded');
      }
    ); 

  }

  getDatesUS() {

    this._dataService.getDatesUs().subscribe(
      data => { 

        data.forEach(item => {

          var date = [];

          var year  = item.show_date_user.substring(0, 4);
          var month = item.show_date_user.substring(4, 6);
          var day   = item.show_date_user.substring(6, 8);

          var dateUS = new Date(year, month, day); 

          var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

          date['order'] = new Date(dateUS.getFullYear() + '-' + dateUS.getMonth() + '-' + dateUS.getDate());
          date['date'] = item.show_date_user;
          date['year'] = dateUS.getFullYear();
          date['month'] = months[dateUS.getMonth()];
          date['day'] = dateUS.getDate();
          date['title'] = item.title.rendered;

          this.allDates.push(date);

        });

        this.sortDates();

      },
      
      err => console.error(err),
      
      () => { 
        $('.loading-screen').fadeOut();
        console.log('Dates US Loaded');
      }
    );

  }

  sortDates() {
    this.allDates.sort((a, b) => a.order - b.order);
  }

}