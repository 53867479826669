import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

import { DataService } from '../data.service';

import { SlickCarouselModule } from 'ngx-slick-carousel';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  public artists;

  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "dots": false,
    "arrows": true,
    "infinite": true
  };

  constructor(private _dataService: DataService) { }

  slickInit(e) {
    console.log('slick initialized');
  }

  ngOnInit() {
    this.getArtists();
  }

  rotateToDiscs() {
  	$('.flip-card-main').addClass('hover');
  }

  rotateToIntro() {
  	$('.flip-card-main').removeClass('hover');
  }

  getArtists() {

    this._dataService.getArtists().subscribe(
      data => { this.artists = data},
      
      err => console.error(err),
      
      () => { 
        $('.loading-screen').fadeOut();
        console.log('Artists Loaded');
      }
    );

  }

}
