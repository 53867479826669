import { Component, OnInit } from '@angular/core';

import {NgForm} from '@angular/forms';

declare var $: any; 

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  date;

  constructor() {
  	this.date = (new Date()).getFullYear();
  }

  ngOnInit() {

  	 $(
        function() {
        	console.log('does this work?');
            $("#dob_picker").datepicker(
            	{
            		dateFormat : "dd/mm/yy",
            		changeMonth: true,
      				changeYear: true
            	}
            );
        }
    );     


  }

  onSubmit(f: NgForm) {

    var formData = [];

    formData['ae']                    = '053de84e36c3606d88ef6ba8548d6b5cb47c6df6953b4240a75f67aec45ca77b';
    formData['ae_activities']         = '{"actions":{"formsubmission":38192},"mailing_list_optins":{"a0S61000000Zh5nEAC":38195}}';
    formData['ae_brand_id']           = '3447166';
    formData['ae_segment_id']         = '979996';
    formData['default_mailing_list']  = 'a0S61000000Zh5nEAC';
    formData['form']                  = '63018';
    formData['js_url']                = 'https://subs.sonymusicfans.com/submit';

    formData['field_email_address']   = f.value.field_email_address; 
    formData['field_first_name']      = f.value.field_first_name;
	formData['field_country_region']  = f.value.field_country_region;
	formData['field_dob']             = f.value.field_dob;

	var data = $(formData).serialize();

	console.log(data);

	// POST all the data to the SMF submission endpoint URL.
	$.ajax({
		type: "POST",
		url: "https://subs.sonymusicfans.com/submit",
		dataType: "json",
		data: data,
		xhrFields: {
			withCredentials: false
		},
		success: function (data) {
			// Do things on successful submission.
			alert("Submitted!");
		},
		error: function (err) {
			console.log(err);
			// Do things when submission has an error.
			alert("An error has occured!");
		}
	});

  }

}
